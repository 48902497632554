import React from 'react';
import Section from '../../components/Section';
import { Heading1, Paragraph } from '../../components/Typography';
import Button from '../../components/Button';
import HeroSection from '../../components/HeroSection';
import UnstyledLink from '../../components/Link';

const Error = () => (
    <>
        <HeroSection paddingTop='8px'>
            <Heading1>Form Submission Error</Heading1>
        </HeroSection>

        <Section
            backgroundColor='white'
            paddingTop='100px'
            paddingBottom='215px'
        >
            <div
                css={`
                    max-width: 800px;
                `}
            >
                <Paragraph>
                    Something went wrong.{' '}
                    <UnstyledLink href='/submit-proof'>
                        Please try again
                    </UnstyledLink>
                    .
                </Paragraph>
                <Paragraph>
                    If you require any further assistance please{' '}
                    <UnstyledLink href='/contact/'>contact us</UnstyledLink> or
                    visit our <UnstyledLink href='/faq/'>FAQ</UnstyledLink>{' '}
                    page.
                </Paragraph>

                <Button backgroundColor='secondary' href='/' as='a'>
                    Home
                </Button>
            </div>
        </Section>
    </>
);
export default Error;
